
/* --------------------------------------------------------
IMPORT ADOBE FONTS
-------------------------------------------------------- */

@import url("https://use.typekit.net/xlz7tni.css");


/* --------------------------------------------------------
HTML / BODY
-------------------------------------------------------- */

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* --------------------------------------------------------
LAYOUT HELPERS
-------------------------------------------------------- */

img {
	display: block;
	max-width: 100%;
}

.clear {
	clear: both;
}


/* --------------------------------------------------------
GLOBAL LINK STYLING
-------------------------------------------------------- */

a:-webkit-any-link {
	text-decoration: none !important;
}

a:hover -webkit-any-link {
	text-decoration: underline !important;
}

a:hover {
	text-decoration: none !important;
}


/* --------------------------------------------------------
LINK STYLING OVERRIDE
-------------------------------------------------------- */

.underlinedLink {
	color:inherit;
	text-decoration: none !important;
}
.underlinedLinkNoRollover {
	color:inherit;
	text-underline-offset: 5px;
	text-decoration: underline !important;
}

.underlinedLink:hover {
	color:inherit;
	cursor: pointer;
	text-underline-offset: 5px;
	text-decoration: underline !important;
}
.underlinedLinkNoRollover:hover {
	color:inherit;
	cursor: pointer;
	text-underline-offset: 5px;
	text-decoration: underline !important;
}

.underlinedLink:visited {
	color:inherit;
}

.asText {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
}


/* --------------------------------------------------------
FEATURED CONDITIONS - HOME PAGE
-------------------------------------------------------- */

.featuredConditionImage {
    width: 100%;
    height: auto;
    border-radius: 20px !important;
}